<template>

<div style="max-width: 800px">

  <div class="card-box">
    <h4>Number Override</h4>
    <p>Manually override how a number is classified</p>
    <CButton @click="loadPath('number-classifier/number-override')" color="info" class="card-box-button">
      Number Override
    </CButton>
  </div>

  <div class="card-box">
    <h4>Pending Overrides</h4>
    <p>Overrides waiting to be approved by the Command Center team</p>
    <CButton @click="loadPath('number-classifier/pending-overrides')" color="info" class="card-box-button">
      Pending Overrides
    </CButton>
  </div>


</div>
</template>

<script>
export default {
  name: 'NumberClassifier',
  methods: {
    //--------------------------------------------------------------------------
    loadPath(path) {
      this.$router.push({
        path: path
      });
    },
  }
}
</script>
